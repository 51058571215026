import InputCustom from 'components/Inputs'
import useRedux from 'hooks/useRedux'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import CustomSelect from 'components/Select'
import { maskMoney } from 'util/masks'

interface ServidoresProps {
  index: number
}

const Servidores = ({ index }: ServidoresProps) => {
  const { atendimentosAbertos, perfis } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  type Fields =
    ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']['register']
  function onChange<T extends keyof Fields>(value: Fields[T], field: T) {
    dispatch(atendimentoActions.setRegisterMatriculaFields(value, field, index))
  }

  useEffect(() => {
    dispatch(atendimentoActions.getConveniosInfoSaga(index))
  }, [dispatch, index])

  return (
    <div className="d-flex flex-column row-gap-15">
      <div className="container-fluid">
        <Row>
          <Col md={4}>
            <label className="label-12">Perfil*</label>
            <CustomSelect
              options={perfis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoPerfilId')
              }}
              value={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .register?.tipoPerfilId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.tipoPerfilId
              }
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula*</label>
            <InputCustom
              name="matricula"
              type="text"
              onChange={(e) => {
                onChange(e.target.value, 'numeroMatricula')
              }}
              placeholder="Digite aqui"
              value={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .register?.numeroMatricula || ''
              }
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.numeroMatricula
              }
              maxLength={15}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Convênio*</label>
            <CustomSelect
              options={
                atendimentosAbertos[index].tabMatriculas.optionsSelect.convenios
              }
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'convenioId')
              }}
              value={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .register?.convenioId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.convenioId
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">
              Margem consignável de empréstimo*
            </label>
            <InputCustom
              name="margemDisponivel"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemDisponivel')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabMatriculas
                    .modalRegisterMatricula.register?.margemDisponivel
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.margemDisponivel
              }
              maxLength={14}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Margem consignável de cartão*</label>
            <InputCustom
              name="margemCartaoDisponivel"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemCartaoDisponivel')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabMatriculas
                    .modalRegisterMatricula.register?.margemCartaoDisponivel
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.margemCartaoDisponivel
              }
              maxLength={14}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Margem cartão benefício*</label>
            <InputCustom
              name="margemCartaoBeneficio"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemCartaoBeneficio')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabMatriculas
                    .modalRegisterMatricula.register?.margemCartaoBeneficio
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.margemCartaoBeneficio
              }
              maxLength={14}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Servidores }
