import React from 'react'
import { RiBookOpenLine } from 'react-icons/ri'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import BeneficiosTabs from '../BeneficiosTabs'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { useSelector } from 'react-redux'
import { removeMaskMoney } from 'util/masks'
import * as yup from 'yup'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { toast } from 'react-toastify'

interface Props {
  isOpen: boolean
  onToggle: () => void
  index: number
}

const ModalMatricula = ({ isOpen, onToggle, index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const schema = yup.object().shape({
    id: yup.string().nullable(),
    numeroMatricula: yup
      .string()
      .max(20)
      .when('tipoPerfil', {
        is: (tipoPerfil: number) =>
          tipoPerfil !== 2 && tipoPerfil !== 0 && tipoPerfil !== 6,
        then: yup.string().required()
      })
      .nullable(),
    tipoPerfil: yup.number().nullable(),
    descricaoPerfil: yup.string().nullable(),
    tipoPerfilId: yup.string().required(),
    convenioId: yup
      .string()
      .when('tipoPerfil', {
        is: (tipoPerfil: number) => {
          return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
        },
        then: yup.string().required()
      })
      .nullable(),
    margemDisponivel: yup
      .number()
      .when('tipoPerfil', {
        is: (tipoPerfil: number) => {
          return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
        },
        then: yup.number().moreThan(0).lessThan(1000000000.0)
      })
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
    margemCartaoDisponivel: yup
      .number()
      .when('tipoPerfil', {
        is: (tipoPerfil: number) => {
          return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
        },
        then: yup.number().moreThan(0).lessThan(1000000000.0)
      })
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
    margemCartaoBeneficio: yup
      .number()
      .when('tipoPerfil', {
        is: (tipoPerfil: number) => {
          return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
        },
        then: yup.number().moreThan(0).lessThan(1000000000.0)
      })
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null))
  })

  const onSave = () => {
    schema
      .validate(
        {
          id: atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
            .register.id,
          numeroMatricula:
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register.numeroMatricula,
          tipoPerfil:
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register.tipoPerfil,
          descricaoPerfil:
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register.descricaoPerfil,
          tipoPerfilId:
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register.tipoPerfilId,
          convenioId:
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register.convenioId,
          margemDisponivel: Number(
            removeMaskMoney(
              atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                .register.margemDisponivel
            )
          ),
          margemCartaoDisponivel: Number(
            removeMaskMoney(
              atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                .register.margemCartaoDisponivel
            )
          ),
          margemCartaoBeneficio: Number(
            removeMaskMoney(
              atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                .register.margemCartaoBeneficio
            )
          )
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(atendimentoActions.adicionarMatriculaSaga(index))
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            atendimentoActions.setRegisterMatriculaInvalidFields(
              true,
              e.path as keyof ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']['invalids'],
              index
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" />
          Cadastro matrícula
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <BeneficiosTabs
          index={index}
          matricula={
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .register
          }
        />
      </ModalBody>
      <ModalFooter>
        <Row className="d-flex column-gap-20">
          <CustomButton onClick={() => onToggle()} background="#662D91">
            Cancelar
          </CustomButton>
          <CustomButton onClick={() => onSave()}>Cadastrar</CustomButton>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ModalMatricula
