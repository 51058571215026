import React from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import useRedux from 'hooks/useRedux'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import CustomSelect from 'components/Select'

interface InssProps {
  index: number
}

const Inss = ({ index }: InssProps) => {
  const { atendimentosAbertos, perfis } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  type Fields =
    ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']['register']
  function onChange<T extends keyof Fields>(value: Fields[T], field: T) {
    dispatch(atendimentoActions.setRegisterMatriculaFields(value, field, index))
  }

  return (
    <div className="d-flex flex-column row-gap-15">
      <div className="container-fluid">
        <Row>
          <Col md={4}>
            <label className="label-12">Perfil*</label>
            <CustomSelect
              options={perfis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoPerfilId')
              }}
              value={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .register?.tipoPerfilId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.tipoPerfilId
              }
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula*</label>
            <InputCustom
              name="matricula"
              type="text"
              onChange={(e) => {
                onChange(e.target.value, 'numeroMatricula')
              }}
              placeholder="Digite aqui"
              value={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  ?.register.numeroMatricula || ''
              }
              invalid={
                atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .invalids?.numeroMatricula
              }
              maxLength={15}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Inss }
