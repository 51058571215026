import { maskCPF, maskDate, removeMaskMoney } from 'util/masks'
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import moment from 'moment'
import { Row } from 'react-data-grid'
import { Tooltip } from '@mui/material'
import * as producaoActions from 'store/modules/producao/actions'
import { TbFileImport } from 'react-icons/tb'
import { AiOutlineEye } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { MdOutlineFileDownload } from 'react-icons/md'

const headerTheme = 'table-header--purple'

export const COLUMNS_HISTORICO: GridColumns<Row> = [
  {
    field: 'equipe',
    headerName: 'Equipe',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    minWidth: 350
  },
  {
    field: 'dataCriacao',
    headerName: 'Data e hora',
    type: 'datetime',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        title={
          params?.value
            ? moment(params?.value).format('DD/MM/YYYY HH:mm:ss')
            : ''
        }
      >
        <span className="table-cell-trucate">
          {params?.value
            ? moment(params?.value).format('DD/MM/YYYY HH:mm:ss')
            : ''}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 180
  },
  {
    field: 'nomeCliente',
    headerName: 'Nome do cliente',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 350
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskCPF(params.row.cpf) || ''}`,
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'adesao',
    headerName: 'Adesão',
    headerClassName: headerTheme,
    minWidth: 120
  },
  {
    field: 'banco',
    headerName: 'Banco',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'produto',
    headerName: 'Produto',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 350
  },
  {
    field: 'nomeDigitador',
    headerName: 'Usuário',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 350
  },
  {
    field: 'quantidadeParcelas',
    headerName: 'Qtde parcelas',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'valorParcela',
    headerName: 'Valor Parcela',
    headerClassName: headerTheme,
    sortComparator: (a: string, b: string) => {
      const valueA = parseFloat(removeMaskMoney(a))
      const valueB = parseFloat(removeMaskMoney(b))

      return valueA - valueB
    },
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params.row.valorParcela}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'valorTotal',
    headerName: 'Valor total',
    headerClassName: headerTheme,
    sortComparator: (a: string, b: string) => {
      const valueA = parseFloat(removeMaskMoney(a))
      const valueB = parseFloat(removeMaskMoney(b))

      return valueA - valueB
    },
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params.row.valorTotal}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'possuiSeguro',
    headerName: 'Possui Seguro',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">
          {params?.value === true ? 'Sim' : 'Não'}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.row?.motivoPendencia || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 140
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => {
      const actions = []

      if (
        params.row.motivoPendencia &&
        params.row.status.toUpperCase() === 'PROPOSTA PENDENTE'
      ) {
        actions.push(
          <GridActionsCellItem
            key={params.row.id}
            onClick={() => {
              producaoActions.setModalPendenciasAnexoCrefazFieldsDispatch(
                params.row.cpf,
                'cpf'
              )
              producaoActions.setModalPendenciasAnexoCrefazFieldsDispatch(
                params.row.adesao,
                'propostaId'
              )
              producaoActions.setModalPendenciasAnexoCrefazFieldsDispatch(
                true,
                'isOpen'
              )
            }}
            icon={<TbFileImport />}
            label="Download"
          />
        )
      }

      return actions
    }
  }
]

export const COLUMNS_UPLOAD_ANEXOS: GridColumns<Row> = [
  {
    field: 'nomeArquivo',
    headerName: 'Nome Arquivo',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'descricaoTipoArquivo',
    headerName: 'Tipo do Documento',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataCriacao',
    headerName: 'Data da Importação',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        onClick={() => {
          producaoActions.visualizarAnexoSagaDispatch(params.row.nomeArquivo)
        }}
        icon={<AiOutlineEye />}
        label="Visualizar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        onClick={() => {
          producaoActions.downloadAnexoSagaDispatch(params.row.nomeArquivo)
        }}
        icon={<MdOutlineFileDownload />}
        label="Download"
      />,
      <GridActionsCellItem
        key={params.row.id}
        onClick={() => {
          producaoActions.deletarAnexoSagaDispatch(params.row.nomeArquivo)
        }}
        icon={<FiTrash />}
        label="Deletar"
      />
    ]
  }
]
