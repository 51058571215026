import React, { useEffect, useState } from 'react'
import SimpleCard from 'components/SimpleCard'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { Col, Row, TabPane } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import CustomSelect from 'components/Select'
import { CardUpload } from 'views/atendimento/style'
import CustomTable from 'components/CustomTable'
import { COLUMNS_DADOS_BANCARIOS_CADASTRADOS } from 'views/atendimento/constants/columns'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import InputCustom from 'components/Inputs'
import { SimpleTitleCard } from 'styles/global'
import { FiGlobe } from 'react-icons/fi'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import * as yup from 'yup'
import { RiCloseLine } from 'react-icons/ri'
import { Switch } from 'components/Switch'
import { validateAndConvertInputValue } from 'util/validateAndConvertInputValue'
import { maskNumber } from 'util/masks'

/**
 * Dados Bancários
 * TabId = 6
 * **/

interface Props {
  index: number
}

const DadosBancarios = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [bancoDisable, setBancoDisable] = useState(false)
  const [hasDadosBancarios, setHasDadosBancarios] = useState(false)

  useEffect(() => {
    dispatch(atendimentoActions.getTiposContaDadosBancariosSaga(index))
    dispatch(atendimentoActions.getBancosDadosBancariosSaga(index))
    dispatch(atendimentoActions.getTiposPixDadosBancariosSaga(index))
    dispatch(atendimentoActions.getTiposContaCrefazDadosBancariosSaga(index))
    dispatch(atendimentoActions.getTemposContaDadosBancariosSaga(index))
    dispatch(atendimentoActions.getDadosBancariosCadastradosSaga(index))
  }, [dispatch, index])

  useEffect(() => {
    dispatch(atendimentoActions.getMatriculasDadosBancariosSaga(index))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios, index, dispatch])

  type InfosDadosBancariosField =
    keyof ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios']['dadosBancarios']

  const onChange = <N extends InfosDadosBancariosField | undefined>(
    value: N extends InfosDadosBancariosField
      ? ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios']['dadosBancarios'][N]
      : never,
    nestedField: N,
    index: number
  ) => {
    let updatedField = {
      ...atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
    }
    if (nestedField === 'tipoContaId') {
      const isOrdemPagamento = atendimentosAbertos[
        index
      ].tabDadosBancarios?.optionsSelect.tiposConta?.some(
        (x) => x.value === value && x.descricao === 'ORDEM DE PAGAMENTO'
      )
      const isContaBmg = atendimentosAbertos[
        index
      ].tabDadosBancarios?.optionsSelect.tiposConta?.some(
        (x) => x.value === value && x.descricao === 'CONTA BMG'
      )
      setBancoDisable(isOrdemPagamento || isContaBmg)
      if (isOrdemPagamento) {
        const banco = atendimentosAbertos[
          index
        ].tabDadosBancarios?.optionsSelect.bancos?.find(
          (x) => x.codigoBanco === '341'
        )
        updatedField = {
          ...atendimentosAbertos[index].tabDadosBancarios.dadosBancarios,
          bancoDadosBancariosId: banco?.id || ''
        }
      }
      if (isContaBmg) {
        const banco = atendimentosAbertos[
          index
        ].tabDadosBancarios?.optionsSelect.bancos?.find(
          (x) => x.codigoBanco === '318'
        )
        updatedField = {
          ...atendimentosAbertos[index].tabDadosBancarios.dadosBancarios,
          bancoDadosBancariosId: banco?.id || ''
        }
      }
    }

    updatedField = {
      ...updatedField,
      [nestedField as string]: value
    }

    dispatch(
      atendimentoActions.setInfosDadosBancariosField(
        updatedField,
        'dadosBancarios',
        index
      )
    )
  }

  const schema = yup.object().shape({
    cpf: yup.string().required(),
    contaCorrenteId: yup.string().nullable(),
    numeroAgencia: yup.string().required().min(1).max(11),
    digitoAgencia: yup
      .string()
      .nullable()
      .max(1)
      .transform((_, val) => (val === Number(val) ? val : null)),
    numeroConta: yup.string().required().min(1).max(11),
    digitoConta: yup.string().required().length(1),
    chavePix: yup
      .string()
      .when('tipoPixId', {
        is: (val: string | null) => !!val,
        then: yup.string().required()
      })
      .nullable(),
    tipoPixId: yup.string().nullable(),
    tipoContaId: yup.string().required(),
    bancoDadosBancariosId: yup.string().required(),
    matriculaId: yup.string().nullable(),
    principal: yup.boolean().required()
  })

  const onSave = () => {
    dispatch(atendimentoActions.cleanInvalidsDadosBancarios(index))
    schema
      .validate(
        {
          cpf: atendimentosAbertos[index].cpf,
          contaCorrenteId:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .contaCorrenteId,
          numeroAgencia:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .numeroAgencia,
          digitoAgencia:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .digitoAgencia,
          numeroConta:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .numeroConta,
          digitoConta:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .digitoConta,
          chavePix:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .chavePix,
          tipoPixId:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .tipoPixId,
          tipoContaId:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .tipoContaId,
          bancoDadosBancariosId:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .bancoDadosBancariosId,
          matriculaId:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .matriculaId,
          principal:
            atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
              .principal
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(atendimentoActions.salvarDadosBancariosSaga(index))
      })
      .catch((err) => {
        err.inner.map((e: yup.ValidationError) => {
          dispatch(
            atendimentoActions.setInfosDadosBancariosField(
              true,
              'invalids',
              index,
              e.path as keyof ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios']['invalids']
            )
          )
        })
      })
  }

  useEffect(() => {
    if (
      !atendimentosAbertos[index].tabDadosBancarios.dadosBancarios.tipoContaId
    ) {
      setBancoDisable(false)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios.tipoContaId,
    index
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setHasDadosBancarios(
      Object.values(
        atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
      ).some((value) => value !== null && value !== '' && value !== false)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabDadosBancarios.dadosBancarios, index])

  const filteredMatriculasOptions = atendimentosAbertos[
    index
  ].tabDadosBancarios.optionsSelect.matriculas
    .filter((matricula) => {
      return (
        matricula.id ===
          atendimentosAbertos[index].tabDadosBancarios.configs.matriculaId ||
        !atendimentosAbertos[
          index
        ].tabDadosBancarios.dadosBancariosCadastrados.some(
          (cadastrado) => cadastrado.matriculaId === matricula.id
        )
      )
    })
    .map((matricula) => ({
      ...matricula,
      numero:
        matricula.numero === '0'
          ? matricula.clientePerfil.descricao
          : `${matricula.clientePerfil.descricao} - ${matricula.numero}`
    }))

  return (
    <TabPane tabId={6}>
      <div className="container-fluid">
        <SimpleCard className="d-flex flex-column row-gap-20">
          <CardUpload className="d-flex flex-column row-gap-20">
            <Row>
              <div className="d-flex justify-content-between">
                <SimpleTitleCard>
                  <FiGlobe className="icon" /> Dados bancários
                </SimpleTitleCard>
              </div>
              <Col md={4}>
                <label className="label-12">Tipo conta*</label>
                <div>
                  <CustomSelect
                    options={
                      atendimentosAbertos[index].tabDadosBancarios
                        ?.optionsSelect.tiposConta
                    }
                    placeholder="Selecione"
                    onChange={(e) => {
                      onChange(e, 'tipoContaId', index)
                    }}
                    value={
                      atendimentosAbertos[index].tabDadosBancarios
                        .dadosBancarios.tipoContaId || ''
                    }
                    accessorLabel="descricao"
                    accessorValue="value"
                    invalid={
                      atendimentosAbertos[index].tabDadosBancarios.invalids
                        .tipoContaId
                    }
                    disabled={
                      atendimentosAbertos[index].tabDadosBancarios.configs
                        .visualizacao
                    }
                  />
                </div>
              </Col>
              <Col md={4}>
                <label className="label-12">Banco*</label>
                <CustomSelect
                  options={
                    atendimentosAbertos[index].tabDadosBancarios?.optionsSelect
                      .bancos
                  }
                  placeholder="Selecione"
                  onChange={(e) => {
                    onChange(e, 'bancoDadosBancariosId', index)
                  }}
                  value={
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .bancoDadosBancariosId || ''
                  }
                  accessorLabel="descricao"
                  accessorValue="id"
                  invalid={
                    atendimentosAbertos[index].tabDadosBancarios.invalids
                      .bancoDadosBancariosId
                  }
                  disabled={
                    bancoDisable ||
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Agência*</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskNumber(e.target.value), 'numeroAgencia', index)
                  }}
                  value={validateAndConvertInputValue(
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .numeroAgencia
                  )}
                  invalid={
                    atendimentosAbertos[index].tabDadosBancarios.invalids
                      .numeroAgencia
                  }
                  maxLength={10}
                  disabled={
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Dígito agência</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskNumber(e.target.value), 'digitoAgencia', index)
                  }}
                  value={
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .digitoAgencia || ''
                  }
                  invalid={
                    atendimentosAbertos[index].tabDadosBancarios.invalids
                      .digitoAgencia
                  }
                  maxLength={1}
                  disabled={
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Número conta*</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskNumber(e.target.value), 'numeroConta', index)
                  }}
                  value={
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .numeroConta || ''
                  }
                  invalid={
                    atendimentosAbertos[index].tabDadosBancarios.invalids
                      .numeroConta
                  }
                  maxLength={10}
                  disabled={
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Dígito conta*</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskNumber(e.target.value), 'digitoConta', index)
                  }}
                  value={
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .digitoConta || ''
                  }
                  invalid={
                    atendimentosAbertos[index].tabDadosBancarios.invalids
                      .digitoConta
                  }
                  maxLength={1}
                  disabled={
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" md={8}>
                <SimpleTitleCard>
                  <FiGlobe className="icon" /> Pix
                </SimpleTitleCard>
                <Row>
                  <Col md={6}>
                    <label className="label-12">Tipo chave</label>
                    <CustomSelect
                      options={
                        atendimentosAbertos[index].tabDadosBancarios
                          ?.optionsSelect.tiposPix
                      }
                      placeholder="Selecione"
                      onChange={(e) => {
                        onChange(e, 'tipoPixId', index)
                      }}
                      value={
                        atendimentosAbertos[index].tabDadosBancarios
                          .dadosBancarios.tipoPixId || ''
                      }
                      accessorLabel="descricao"
                      accessorValue="id"
                      invalid={
                        atendimentosAbertos[index].tabDadosBancarios.invalids
                          .tipoPixId
                      }
                      disabled={
                        atendimentosAbertos[index].tabDadosBancarios.configs
                          .visualizacao
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <label className="label-12">Chave Pix</label>
                    <InputCustom
                      type="text"
                      placeholder="Digite aqui"
                      onChange={(e) => {
                        onChange(e.target.value, 'chavePix', index)
                      }}
                      value={
                        atendimentosAbertos[index].tabDadosBancarios
                          .dadosBancarios.chavePix || ''
                      }
                      invalid={
                        atendimentosAbertos[index].tabDadosBancarios.invalids
                          .chavePix
                      }
                      maxLength={10}
                      disabled={
                        atendimentosAbertos[index].tabDadosBancarios.configs
                          .visualizacao
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <SimpleTitleCard>
                  <FiGlobe className="icon" /> Vínculo matrícula
                </SimpleTitleCard>
                <Row>
                  <Col md={12}>
                    <label className="label-12">Matrícula</label>
                    <CustomSelect
                      options={filteredMatriculasOptions}
                      placeholder="Selecione"
                      onChange={(e) => {
                        onChange(e, 'matriculaId', index)
                      }}
                      value={
                        atendimentosAbertos[index].tabDadosBancarios
                          .dadosBancarios.matriculaId || ''
                      }
                      accessorLabel="numero"
                      accessorValue="id"
                      invalid={
                        atendimentosAbertos[index].tabDadosBancarios.invalids
                          .matriculaId
                      }
                      disabled={
                        atendimentosAbertos[index].tabDadosBancarios.configs
                          .visualizacao
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <div>
                <label className="label-12">Principal</label>
                <Switch
                  onChange={(e) => {
                    onChange(e.target.checked, 'principal', index)
                  }}
                  checked={
                    atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                      .principal
                  }
                  disabled={
                    atendimentosAbertos[index].tabDadosBancarios.configs
                      .visualizacao
                  }
                />
              </div>
            </Row>
            <Row>
              {!atendimentosAbertos[index].tabDadosBancarios.configs
                .visualizacao && (
                <div
                  onClick={() => {
                    onSave()
                  }}
                  className="save-btn btn"
                >
                  <AiOutlinePlusCircle className="icon" />
                  {atendimentosAbertos[index].tabDadosBancarios.dadosBancarios
                    .contaCorrenteId
                    ? `Atualizar Informações`
                    : `Salvar Informações`}
                </div>
              )}
              {hasDadosBancarios && (
                <div
                  onClick={() => {
                    dispatch(atendimentoActions.cleanDadosBancarios(index))
                  }}
                  className="clean-btn btn"
                >
                  <RiCloseLine className="icon" />
                  Limpar campos
                </div>
              )}
            </Row>
          </CardUpload>
          <CustomTable
            columns={COLUMNS_DADOS_BANCARIOS_CADASTRADOS}
            data={
              atendimentosAbertos[index].tabDadosBancarios
                .dadosBancariosCadastrados
            }
            paginationMode="client"
          />
        </SimpleCard>
      </div>
    </TabPane>
  )
}

export default DadosBancarios
