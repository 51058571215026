import {
  InfoCliente,
  InfosChamada,
  Matricula,
  MatriculaInvalid,
  OptionsSelect,
  Referencia,
  ReferenciaInvalid,
  InfosMatriculas,
  TabProduto
} from 'models/atendimento/types'
import {
  Convenio,
  Perfil,
  ProdutoSimulacao,
  SimulacaoCrefaz
} from 'models/types'
import store from 'store'
import {
  ATENDIMENTO,
  CadastroCliente,
  TYPES,
  ModalCoberturasSeguroPrestamista,
  ModalSeguroSindnapi
} from './types'

export const toggleFormularioNecessidade = (value: boolean) => ({
  type: TYPES.TOGGLE_FOMULARIO_NECESSIDADE,
  value
})

export const setTabFormulario = (value: number) => ({
  type: TYPES.SET_TAB_FORMULARIO,
  value
})

export const setInfosFormulario = (
  value: string | number | boolean,
  field: string
) => ({
  type: TYPES.SET_INFOS_FORMULARIO,
  value,
  field
})

export const cleanInfosFormulario = () => ({
  type: TYPES.CLEAN_INFOS_FORMULARIO
})

export const getFormularioAtendimento30Saga = (cpf: string) => ({
  type: TYPES.GET_FORMULARIO_ATENDIMENTO_30_SAGA,
  cpf
})

export const getFormularioAtendimento30SagaDispatch = (cpf: string) =>
  store.dispatch({
    type: TYPES.GET_FORMULARIO_ATENDIMENTO_30_SAGA,
    cpf
  })

export const salvarFormularioAtendimento30Saga = (cpf: string) => ({
  type: TYPES.SALVAR_FORMULARIO_ATENDIMENTO_30_SAGA,
  cpf
})

export const setLoadingStartAtendimento = (value: boolean) => ({
  type: TYPES.SET_LOADING_START_ATENDIMENTO,
  value
})

export const startAtendimentoSaga = (cpf: string, indexTabCliente = 2) => ({
  type: TYPES.START_ATENDIMENTO_SAGA,
  cpf,
  indexTabCliente
})

export const getConveniosSaga = () => ({
  type: TYPES.GET_CONVENIOS_SAGA
})

export const getConveniosInfoSaga = (index: number) => ({
  type: TYPES.GET_CONVENIOS_INFO_SAGA,
  index
})

export const getPerfisSaga = () => ({
  type: TYPES.GET_PERFIS_SAGA
})

export const getProfissoesSaga = (index: number) => ({
  type: TYPES.GET_PROFISSOES_SAGA,
  index
})

export const setInvalidCadastroClienteModal = (
  value: boolean,
  field: string
) => ({
  type: TYPES.SET_INVALID_CADASTRO_CLIENTE_MODAL,
  value,
  field
})

export const cleanInvalidCadastroClienteModal = () => ({
  type: TYPES.CLEAN_INVALID_CADASTRO_CLIENTE_MODAL
})

export const salvarCadastroSimplificadoSaga = () => ({
  type: TYPES.SALVAR_CADASTRO_SIMPLIFICADO_SAGA
})

export const getInformacoesSimplificadasSaga = (cpf: string) => ({
  type: TYPES.GET_INFORMACOES_SIMPLIFICADAS_SAGA,
  cpf
})

export const discarClienteSaga = (index: number, telefoneId?: string) => ({
  type: TYPES.DISCAR_CLIENTE_SAGA,
  index,
  telefoneId
})

export const startAtendimento = (cpf: string, indexTabCliente: number) => ({
  type: TYPES.START_ATENDIMENTO,
  cpf,
  indexTabCliente
})

export const endAtendimento = (index: number) => ({
  type: TYPES.END_ATENDIMENTO,
  index
})

export const setModalAtendimentoAgendado = (value: boolean, cpf: string) => ({
  type: TYPES.SET_MODAL_ATENDIMENTO_AGENDADO,
  value,
  cpf
})

type InfosEnviaSmsField =
  keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']['modalSms']

export const setInfoContatoModalSmsFieldDispatch = <
  T extends InfosEnviaSmsField
>(
  value: T extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']['modalSms']
    ? ATENDIMENTO['atendimentosAbertos'][number]['tabContato']['modalSms'][T]
    : never,
  field: T,
  index: number
) =>
  store.dispatch({
    type: TYPES.SET_INFO_CONTATO_MODAL_SMS_FIELD_DISPATCH,
    value,
    field,
    index
  })

export const setInfoContatoModalSmsField = <T extends InfosEnviaSmsField>(
  value: T extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']['modalSms']
    ? ATENDIMENTO['atendimentosAbertos'][number]['tabContato']['modalSms'][T]
    : never,
  field: T,
  index: number
) => ({
  type: TYPES.SET_INFO_CONTATO_MODAL_SMS_FIELD_DISPATCH,
  value,
  field,
  index
})

export const getConversaSmsSaga = (telefone: number, index: number) => ({
  type: TYPES.GET_CONVERSA_SMS_SAGA,
  telefone,
  index
})

export const enviarSmsSaga = (index: number) => ({
  type: TYPES.ENVIAR_SMS_SAGA,
  index
})

export const finalizaAgendamentoSaga = (index: number) => ({
  type: TYPES.FINALIZA_AGENDAMENTO_SAGA,
  index
})

export type CadastroClienteField = keyof CadastroCliente
export const setInfosCadastroClienteModal = (value: CadastroCliente) => ({
  type: TYPES.SET_INFOS_CADASTRO_CLIENTE_MODAL,
  value
})

export const cleanInfosCadastroClienteModal = () => ({
  type: TYPES.CLEAN_INFOS_CADASTRO_CLIENTE_MODAL
})

export const setConveniosModal = (value: Convenio[]) => ({
  type: TYPES.SET_CONVENIOS_MODAL,
  value
})

export const setPerfisModal = (value: Perfil[]) => ({
  type: TYPES.SET_PERFIS_MODAL,
  value
})

export const toggleConfirmacaoCadastro = (value: boolean) => ({
  type: TYPES.TOGGLE_CONFIRMACAO_CADASTRO,
  value
})

export const toggleCadastroSimplificado = (value: boolean) => ({
  type: TYPES.TOGGLE_CADASTRO_SIMPLIFICADO,
  value
})

export const cpfAtendimento = (value: string) => ({
  type: TYPES.SET_CPF_ATENDIMENTO,
  value
})

export const cleanCpfAtendimento = () => ({
  type: TYPES.CLEAN_CPF_ATENDIMENTO
})

export const setTabClienteActive = (index: number | string) => ({
  type: TYPES.SET_TAB_CLIENTE_ACTIVE,
  index
})

export const setTabAtendimentoActive = (
  value: number | string,
  index: number
) => ({
  type: TYPES.SET_TAB_ATENDIMENTO_ACTIVE,
  value,
  index
})

export const getPerfilClienteSaga = (index: number) => ({
  type: TYPES.GET_PERFIL_CLIENTE_SAGA,
  index
})

/**Tab  Contato */
export const getAtendimentosSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_LISTA_ATENDIMENTOS_SAGA,
  cpf,
  index
})

export const getTabulacoesSaga = (index: number) => ({
  type: TYPES.GET_TABULACOES_SAGA,
  index
})

export const getTiposAtendimentoSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_ATENDIMENTO_SAGA,
  index
})

export const tabularAtendimentoSaga = (index: number) => ({
  type: TYPES.TABULAR_ATENDIMENTO_SAGA,
  index
})

export const tabularAtendimentoDispatchSaga = (
  cpf: string,
  numeroChamada?: string,
  tabulacaoAutomatica?: boolean
) =>
  store.dispatch({
    type: TYPES.TABULAR_ATENDIMENTO_DISPATCH_SAGA,
    cpf,
    numeroChamada,
    tabulacaoAutomatica
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setInfosContato = (value: any, cpf: string) => ({
  type: TYPES.SET_INFOS_CONTATO,
  value,
  cpf
})

type InfosContatoField =
  keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']
export const setInfosContatoField = <T extends InfosContatoField>(
  value: T extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']
    ? ATENDIMENTO['atendimentosAbertos'][number]['tabContato'][T]
    : never,
  field: T,
  index: number
) => ({
  type: TYPES.SET_INFOS_CONTATO_FIELD,
  value,
  field,
  index
})

type InfosChamadaField = keyof InfosChamada
export const setInfosChamadaContato = <T extends InfosChamadaField>(
  value: T extends keyof InfosChamada ? InfosChamada[T] : never,
  field: T,
  index: number
) => ({
  type: TYPES.SET_INFOS_CHAMADA_CONTATO,
  value,
  field,
  index
})

export const setInfosChamadaContatoByCpfDispatch = <
  T extends InfosChamadaField
>(
  value: T extends keyof InfosChamada ? InfosChamada[T] : never,
  field: T,
  cpf: string
) =>
  store.dispatch({
    type: TYPES.SET_INFOS_CHAMADA_CONTATO_BY_CPF_DISPATCH,
    value,
    field,
    cpf
  })

export const setInfosChamadaInvalidContato = (
  value: boolean,
  field: string,
  index: number
) => ({
  type: TYPES.SET_INFOS_CHAMADA_INVALID_CONTATO,
  value,
  field,
  index
})

export const cleanInfosChamadaInvalidContato = (index: number) => ({
  type: TYPES.CLEAN_INFOS_CHAMADA_INVALID_CONTATO,
  index
})

export const setPaginationAtendimentos = (
  value: number,
  field: string,
  index: number
) => ({
  type: TYPES.SET_PAGINATION_ATENDIMENTOS,
  value,
  field,
  index
})

export const enriquecerDadosSaga = (
  cpf: string,
  index: number,
  automatico?: boolean
) => ({
  type: TYPES.ENRIQUECER_DADOS_SAGA,
  cpf,
  index,
  automatico
})

export const validarNovoAtendimentoSaga = (cpf: string, index: number) => ({
  type: TYPES.VALIDAR_NOVO_ATENDIMENTO_SAGA,
  cpf,
  index
})

export const toggleEnriquecimento = (value: boolean, cpf: string) => ({
  type: TYPES.TOGGLE_ENRIQUECIMENTO,
  value,
  cpf
})

export const setEnriquecido = (index: number) => ({
  type: TYPES.SET_ENRIQUECIDO,
  index
})

export const verificaNaoPertube = (cpf: string) => ({
  type: TYPES.VERIFICA_NAO_PERTUBE_SAGA,
  cpf
})

export const getInformacoesLeadSaga = (
  cpf: string,
  matriculas: string[],
  index: number
) => ({
  type: TYPES.GET_INFORMACOES_LEAD_SAGA,
  cpf,
  matriculas,
  index
})

export const getIntegracaoSaga = (index: number) => ({
  type: TYPES.GET_INTEGRACAO_SAGA,
  index
})

export const setIntegracao = (value: string, index: number) => ({
  type: TYPES.SET_INTEGRACAO,
  value,
  index
})

export const setMensagemErroIntegracao = (value: string, index: number) => ({
  type: TYPES.SET_MENSAGEM_ERRO_INTEGRACAO,
  value,
  index
})

/**Tab  Produtos */
type FieldsTabProdutos = keyof TabProduto
export const setInfosProduto = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  field: FieldsTabProdutos,
  index: number
) => ({
  type: TYPES.SET_INFOS_PRODUTO,
  value,
  field,
  index
})

export const setLoadingBeneficio = (
  value: boolean,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.SET_LOADING_BENEFICIO_IN100,
  value,
  index,
  indexBeneficio
})

export const setLoadingSimulacao = (value: boolean, index: number) => ({
  type: TYPES.SET_LOADING_SIMULACAO,
  value,
  index
})

type FieldsProduto = keyof ProdutoSimulacao
export const setInfosCartaoConsig = (
  value: string | number | boolean,
  field: FieldsProduto,
  index: number,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_CARTAO_CONSIG,
  value,
  field,
  index,
  matricula
})

export const setInfosCartaoBeneficio = (
  value: string | number | boolean,
  field: FieldsProduto,
  index: number,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_CARTAO_BENEFICIO,
  value,
  field,
  index,
  matricula
})

export const setInfosSaqueComplementarConsignado = (
  value: string | number | boolean,
  field: FieldsProduto,
  index: number,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO,
  value,
  field,
  index,
  matricula
})

export const setInfosSaqueComplementarBeneficio = (
  value: string | number | boolean,
  field: FieldsProduto,
  index: number,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO,
  value,
  field,
  index,
  matricula
})

export const selectPortabilidadeProdutoPortabilidade = (
  index: number,
  matricula: string,
  indexContrato: number,
  indexPortabilidade: number
) => ({
  type: TYPES.SELECT_PORTABILIDADE_PRODUTO_PORTABILIDADE,
  index,
  matricula,
  indexContrato,
  indexPortabilidade
})

export const selectPortabilidadeProdutoAssociado = (
  index: number,
  matricula: string,
  indexContrato: number,
  indexPortabilidade: number,
  indexRefinPortabilidade: number
) => ({
  type: TYPES.SELECT_PORTABILIDADE_PRODUTO_ASSOCIADO,
  index,
  matricula,
  indexContrato,
  indexPortabilidade,
  indexRefinPortabilidade
})

type FieldsInfosCrefaz = keyof SimulacaoCrefaz['selecionados']
interface InfosCrefaz<T extends FieldsInfosCrefaz> {
  value: T extends FieldsInfosCrefaz
    ? SimulacaoCrefaz['selecionados'][T]
    : never
  field: T
  index: number
  indexProduto: number
}
export const setInfosCrefaz = <T extends FieldsInfosCrefaz>({
  value,
  field,
  index,
  indexProduto
}: InfosCrefaz<T>) => ({
  type: TYPES.SET_INFOS_CREFAZ,
  value,
  field,
  index,
  indexProduto
})

type FieldsInfosConvenioDadosCrefaz =
  keyof SimulacaoCrefaz['selecionados']['convenioDados'][number]
interface InfosConvenioDadosCrefaz<T extends FieldsInfosConvenioDadosCrefaz> {
  value: T extends FieldsInfosConvenioDadosCrefaz
    ? SimulacaoCrefaz['selecionados']['convenioDados'][number][T]
    : never
  field: T
  index: number
  indexProduto: number
  indexConvenioDado: number
}

export const setInfosConvenioDadosCrefaz = <
  T extends FieldsInfosConvenioDadosCrefaz
>({
  value,
  field,
  index,
  indexProduto,
  indexConvenioDado
}: InfosConvenioDadosCrefaz<T>) => ({
  type: TYPES.SET_INFOS_CONVENIO_DADOS_CREFAZ,
  value,
  field,
  index,
  indexProduto,
  indexConvenioDado
})

export const onCheckProdutoCrefaz = (
  value: SimulacaoCrefaz[],
  index: number
) => ({
  type: TYPES.ON_CHECK_PRODUTO_CREFAZ,
  value,
  index
})

export const setInvalidContratacao = (value: boolean, index: number) => ({
  type: TYPES.SET_INVALID_CONTRATACAO,
  value,
  index
})

export const getPropostasCrmSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_PROPOSTAS_CRM_SAGA,
  cpf,
  index
})

export const solicitarSimulacaoFgtsSaga = (cpf: string, index: number) => ({
  type: TYPES.SOLICITAR_SIMULACAO_FGTS_SAGA,
  cpf,
  index
})

export const getParcelasFgtsSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_PARCELAS_FGTS_SAGA,
  cpf,
  index
})

export const recalculoPropostaSaga = (
  cpf: string,
  numeroMatricula: string,
  quantidadeparcelas: number,
  valorTotalFinanciado: number,
  valorParcela: number,
  servico: number,
  codigoProduto: number,
  banco: number,
  numeroProposta: string | null = null,
  taxaOrigem: number | null = null,
  quantidadeTotalParcelas: number,
  saldoDevedor: number | null = null,
  index: number,
  indexPortabilidade?: number | null,
  indexRefinPortabilidade?: number | null,
  codigoBanco?: string | null
) => ({
  type: TYPES.RECALCULO_PROPOSTA_SAGA,
  cpf,
  numeroMatricula,
  quantidadeparcelas,
  valorTotalFinanciado,
  valorParcela,
  servico,
  codigoProduto,
  banco,
  numeroProposta,
  taxaOrigem,
  quantidadeTotalParcelas,
  saldoDevedor,
  index,
  indexPortabilidade,
  indexRefinPortabilidade,
  codigoBanco
})

export const recalculoPropostaFgtsSaga = (
  cpf: string,
  valor: number,
  parcelas: number,
  index: number
) => ({
  type: TYPES.RECALCULO_PROPOSTA_FGTS_SAGA,
  cpf,
  parcelas,
  valor,
  index
})

export const getResumoContratacaoSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_RESUMO_CONTRATACAO_SAGA,
  cpf,
  index
})

export const getSolicitacoesIN100Saga = (cpf: string) => ({
  type: TYPES.GET_SOLICITACOES_IN100_SAGA,
  cpf
})

export const getTelefonesSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_TELEFONES_SAGA,
  cpf,
  index
})

export const inserirSolicitacaoIN100Saga = (
  cpf: string,
  telefone: string,
  matricula: string,
  index: number
) => ({
  type: TYPES.INSERIR_SOLICITACOES_IN100_SAGA,
  cpf,
  telefone,
  matricula,
  index
})

export const validaStatusIN100Saga = (
  cpf: string,
  matricula: string,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.VALIDA_STATUS_IN100_SAGA,
  cpf,
  matricula,
  index,
  indexBeneficio
})

export const validacaoIN100Saga = (
  cpf: string,
  matricula: string,
  token: string
) => ({
  type: TYPES.VALIDACAO_IN100_SAGA,
  cpf,
  matricula,
  token
})

export const consentimentoItauSaga = (
  cpf: string,
  telefone: string,
  matricula: string,
  canal: string
) => ({
  type: TYPES.CONSENTIMENTO_ITAU_SAGA,
  cpf,
  telefone,
  matricula,
  canal
})
export const consultaConsentimentoItau = (
  cpf: string,
  matricula: string,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.CONSULTA_CONSENTIMENTO_ITAU_SAGA,
  cpf,
  matricula,
  index,
  indexBeneficio
})

export const getProdutosContratadosSaga = (cpf: string, index: number) => ({
  type: TYPES.GET_PRODUTOS_CONTRATADOS_SAGA,
  cpf,
  index
})

export const validacaoContratacaoSaga = (cpf: string, index: number) => ({
  type: TYPES.VALIDACAO_CONTRATACAO_SAGA,
  cpf,
  index
})

interface FinalizacaoContratacaoProps {
  cpf: string
  index: number
}

export const finalizacaoContratacao = ({
  cpf,
  index
}: FinalizacaoContratacaoProps) => ({
  type: TYPES.FINALIZACAO_CONTRATACAO_SAGA,
  cpf,
  index
})

export const selectProduto = (
  value: { servico: number; matricula: string; produtoCrefazId?: number },
  index: number
) => ({
  type: TYPES.SELECT_PRODUTO,
  value,
  index
})

export const toggleDetalhamento = (index: number) => ({
  type: TYPES.TOGGLE_DETALHAMENTO,
  index
})

export const setProdutosSelecionados = (
  value: ProdutoSimulacao[],
  index: number
) => ({
  type: TYPES.SET_PRODUTOS_SELECIONADOS,
  value,
  index
})

export const setSomatorioTotalProdutos = (value: number, index: number) => ({
  type: TYPES.SET_SOMATORIO_TOTAL_PRODUTOS,
  value,
  index
})

export const setSomatorioResumo = (value: number, index: number) => ({
  type: TYPES.SET_SOMATORIO_RESUMO,
  value,
  index
})

/**Tab Informações */

export const getDadosEnderecoSaga = (index: number) => ({
  type: TYPES.GET_DADOS_ENDERECO_SAGA,
  index
})

export const getInformacoesClienteSaga = (
  cpf: string,
  index: number,
  onUpdate?: boolean
) => ({
  type: TYPES.GET_INFORMACOES_CLIENTE_SAGA,
  cpf,
  index,
  onUpdate
})

export const getCidadesSaga = (index: number, siglaEstado?: string) => ({
  type: TYPES.GET_CIDADES_SAGA,
  index,
  siglaEstado
})

export const getCidadesNascimentoSaga = (
  index: number,
  siglaEstado?: string
) => ({
  type: TYPES.GET_CIDADES_NASCIMENTO_SAGA,
  index,
  siglaEstado
})

export const getGenerosSaga = (index: number) => ({
  type: TYPES.GET_GENEROS_SAGA,
  index
})

export const getOrgaosEmissoresSaga = (index: number) => ({
  type: TYPES.GET_ORGAOS_EMISSORES_SAGA,
  index
})

export const getEstadosSaga = (index: number) => ({
  type: TYPES.GET_ESTADOS_SAGA,
  index
})

export const getEscolaridadesSaga = (index: number) => ({
  type: TYPES.GET_ESCOLARIDADES_SAGA,
  index
})

export const getEstadosCivisSaga = (index: number) => ({
  type: TYPES.GET_ESTADOS_CIVIS_SAGA,
  index
})

export const getTiposContaSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_CONTA_SAGA,
  index
})

export const getBancosSaga = (index: number) => ({
  type: TYPES.GET_BANCOS_SAGA,
  index
})

export const getPaisesSaga = (index: number) => ({
  type: TYPES.GET_PAISES_SAGA,
  index
})

export const getTemposEmpregoSaga = (index: number) => ({
  type: TYPES.GET_TEMPOS_EMPREGO_SAGA,
  index
})

export const getGrausReferenciaSaga = (index: number) => ({
  type: TYPES.GET_GRAUS_REFERENCIA_SAGA,
  index
})

export const setActiveTabBeneficio = (value: number, index: number) => ({
  type: TYPES.SET_INFORMACOES_ACTIVE_TAB_BENEFICIO,
  value,
  index
})

export const setInformacoes = (value: InfoCliente, index: number) => ({
  type: TYPES.SET_INFORMACOES,
  value,
  index
})

export const cleanInformacoes = (index: number) => ({
  type: TYPES.CLEAN_INFORMACOES,
  index
})

export const setInformacoesField = (
  value: string | number | Matricula[] | Referencia[],
  field: string,
  index: number
) => ({
  type: TYPES.SET_INFORMACOES_FIELD,
  value,
  field,
  index
})

export const setInvalidsInformacoesField = (
  value: boolean | MatriculaInvalid[] | ReferenciaInvalid[],
  field: string,
  index: number
) => ({
  type: TYPES.SET_INVALID_INFORMACOES_FIELD,
  value,
  field,
  index
})

export const setInvalidsInformacoesFieldArray = (
  value: boolean,
  field: string,
  index: number,
  fieldComplementary: string,
  indexTab: number
) => ({
  type: TYPES.SET_INVALID_INFORMACOES_FIELD_ARRAY,
  value,
  field,
  index,
  fieldComplementary,
  indexTab
})

export const adicionarPerfil = (value: Perfil, index: number) => ({
  type: TYPES.ADICIONAR_PERFIL,
  value,
  index
})

export const adicionarReferencia = (index: number) => ({
  type: TYPES.ADICIONAR_REFERENCIA,
  index
})

export const removerReferencia = (indexReferencia: number, index: number) => ({
  type: TYPES.REMOVER_REFERENCIA,
  indexReferencia,
  index
})

export const cleanInvalidsInformacoesField = (index: number) => ({
  type: TYPES.CLEAN_INVALID_INFORMACOES_FIELD,
  index
})

export const salvarInformacoesClienteSaga = (index: number) => ({
  type: TYPES.SALVAR_INFORMACOES_CLIENTE_SAGA,
  index
})

type FieldsSelectsInformacoes = keyof OptionsSelect
export const setOptionsSelectsInformacoes = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  field: FieldsSelectsInformacoes,
  index: number
) => ({
  type: TYPES.SET_OPTIONS_SELECTS_INFORMACOES,
  value,
  field,
  index
})

export const cleanInformacoesEndereco = (index: number) => ({
  type: TYPES.CLEAN_INVALID_INFOS_CEP,
  index
})

/**Tab Matriculas */
export const getContratosSaga = (
  cpf: string,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.GET_CONTRATOS_SAGA,
  cpf,
  index,
  indexBeneficio
})

export const setTabsMatriculas = (index: number) => ({
  type: TYPES.SET_TABS_MATRICULAS,
  index
})

type FieldsMatriculas = keyof InfosMatriculas
export const setInfosMatriculas = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value:
    | InfosMatriculas['contratos']
    | InfosMatriculas['infosConsig']
    | InfosMatriculas['tabelaContratos'],
  field: FieldsMatriculas,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.SET_INFOS_MATRICULAS,
  value,
  field,
  index,
  indexBeneficio
})

export const getInfosClienteConsigSaga = (
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.GET_INFOS_CLIENTE_CONSIG_SAGA,
  index,
  indexBeneficio
})

export const setPaginationContratos = (
  value: number,
  field: string,
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.SET_PAGINATION_CONTRATOS,
  value,
  field,
  index,
  indexBeneficio
})

type InfosModalMatriculasFields =
  ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']
export const setInfosModalMatriculaFields = <
  T extends keyof InfosModalMatriculasFields
>(
  value: InfosModalMatriculasFields[T],
  field: T,
  index: number
) => ({
  type: TYPES.SET_INFOS_MODAL_MATRICULA_FIELDS,
  value,
  field,
  index
})

type RegisterMatriculaFields =
  ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']['register']
export const setRegisterMatriculaFields = <
  T extends keyof RegisterMatriculaFields
>(
  value: RegisterMatriculaFields[T],
  field: T,
  index: number
) => ({
  type: TYPES.SET_REGISTER_MATRICULA_FIELDS,
  value,
  field,
  index
})

type RegisterMatriculaInvalidFields =
  ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['modalRegisterMatricula']['invalids']
export const setRegisterMatriculaInvalidFields = <
  T extends keyof RegisterMatriculaInvalidFields
>(
  value: RegisterMatriculaInvalidFields[T],
  field: T,
  index: number
) => ({
  type: TYPES.SET_REGISTER_MATRICULA_INVALID_FIELDS,
  value,
  field,
  index
})

export const cleanRegisterMatriculaInvalid = (index: number) => ({
  type: TYPES.CLEAN_REGISTER_MATRICULA_INVALID,
  index
})

type OptionsSelectsMatricula =
  ATENDIMENTO['atendimentosAbertos'][number]['tabMatriculas']['optionsSelect']
export const setOptionsSelectsMatriculas = <
  T extends keyof OptionsSelectsMatricula
>(
  value: OptionsSelectsMatricula[T],
  field: T,
  index: number
) => ({
  type: TYPES.SET_OPTIONS_SELECTS_MATRICULAS,
  value,
  field,
  index
})

export const adicionarMatriculaSaga = (index: number) => ({
  type: TYPES.ADICIONAR_MATRICULA_SAGA,
  index
})

export const removerMatriculaSaga = (
  index: number,
  indexBeneficio: number
) => ({
  type: TYPES.REMOVER_MATRICULA_SAGA,
  index,
  indexBeneficio
})

export const adicionarNovoTelefoneSaga = (
  telefone: string,
  isWhatsApp: boolean,
  index: number
) => ({
  type: TYPES.ADICIONAR_NOVO_TELEFONE_SAGA,
  telefone,
  isWhatsApp,
  index
})

export const getMatriculasModalIndicacaoSaga = (
  cpf: string,
  index: number
) => ({
  type: TYPES.GET_MATRICULAS_MODAL_INDICACAO_SAGA,
  cpf,
  index
})

export const setModalAgregacao = (
  value: number | string | ProdutoSimulacao,
  field: string
) => ({
  type: TYPES.SET_MODAL_AGREGACAO,
  value,
  field
})

export const cleanModalAgregacao = () => ({
  type: TYPES.CLEAN_MODAL_AGREGACAO
})

export const recalculoSimulacaoRefinItauSaga = (
  cpf: string,
  numeroMatricula: string,
  quantidadeParcelas: number,
  valorParcela: number,
  servico: number,
  codigoProduto: number,
  banco: number,
  contrato: string,
  valorAgregacao: number,
  index: number,
  id: string,
  codigoSimulacao: number,
  novaMargem: number
) => ({
  type: TYPES.RECALCULO_SIMULACAO_REFIN_ITAU_SAGA,
  cpf,
  numeroMatricula,
  quantidadeParcelas,
  valorParcela,
  servico,
  codigoProduto,
  banco,
  contrato,
  valorAgregacao,
  index,
  id,
  codigoSimulacao,
  novaMargem
})

export const recalculoCrefazSaga = (
  produtoIndex: number,
  convenioIndex: number,
  index: number
) => ({
  type: TYPES.RECALCULO_CREFAZ_SAGA,
  produtoIndex,
  convenioIndex,
  index
})

export const setPlanoSeguroPrestamistaSelecionado = (
  codigoPlano: string,
  index: number
) => ({
  type: TYPES.SET_PLANO_SEGURO_PRESTAMISTA_SELECIONADO,
  codigoPlano,
  index
})

export const setModalCoberturaSeguroPrestamista = (
  value: ModalCoberturasSeguroPrestamista
) => ({
  type: TYPES.SET_MODAL_COBERTURA_SEGURO_PRESTAMISTA,
  value
})

export const cleanModalCoberturaSeguroPrestamista = () => ({
  type: TYPES.CLEAN_MODAL_COBERTURA_SEGURO_PRESTAMISTA
})

export const setModalSeguroSindnapi = (value: ModalSeguroSindnapi) => ({
  type: TYPES.SET_MODAL_SEGURO_SINDNAPI,
  value
})

export const cleanModalSeguroSindnapi = () => ({
  type: TYPES.CLEAN_MODAL_SEGURO_SINDNAPI
})

/**Tab Upload */
type InfosUploadField =
  keyof ATENDIMENTO['atendimentosAbertos'][number]['tabUpload']
export const setInfosUploadField = <T extends InfosUploadField>(
  value: T extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabUpload']
    ? ATENDIMENTO['atendimentosAbertos'][number]['tabUpload'][T]
    : never,
  field: T,
  index: number
) => ({
  type: TYPES.SET_INFOS_UPLOAD_FIELD,
  value,
  field,
  index
})

export const getTiposArquivoCrefazSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_ARQUIVO_CREFAZ_SAGA,
  index
})

export const salvarAnexoSaga = (index: number) => ({
  type: TYPES.SALVAR_ANEXO_SAGA,
  index
})

export const visualizarAnexoSagaDispatch = (
  nomeArquivo: string,
  index: number
) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_ANEXO_SAGA_DISPATCH,
    nomeArquivo,
    index
  })

export const downloadAnexoSagaDispatch = (nomeArquivo: string, index: number) =>
  store.dispatch({
    type: TYPES.DOWNLOAD_ANEXO_SAGA_DISPATCH,
    nomeArquivo,
    index
  })

export const deletarAnexoSagaDispatch = (nomeArquivo: string, index: number) =>
  store.dispatch({
    type: TYPES.DELETAR_ANEXO_SAGA_DISPATCH,
    nomeArquivo,
    index
  })

export const getAnexosSaga = (index: number) => ({
  type: TYPES.GET_ANEXOS_SAGA,
  index
})

/** Tab Dados Bancários */
type InfosDadosBancariosField =
  keyof ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios']

export const setInfosDadosBancariosField = <
  T extends InfosDadosBancariosField,
  N extends
    | keyof ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios'][T]
    | undefined
>(
  value: N extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios'][T]
    ? ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios'][T][N]
    : ATENDIMENTO['atendimentosAbertos'][number]['tabDadosBancarios'][T],
  field: T,
  index: number,
  nestedField?: N
) => ({
  type: TYPES.SET_INFOS_DADOS_BANCARIOS_FIELD,
  value,
  field,
  nestedField,
  index
})

export const cleanInvalidsDadosBancarios = (index: number) => ({
  type: TYPES.CLEAN_INVALIDS_DADOS_BANCARIOS,
  index
})

export const cleanDadosBancarios = (index: number) => ({
  type: TYPES.CLEAN_DADOS_BANCARIOS,
  index
})

export const getTiposContaDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_CONTA_DADOS_BANCARIOS_SAGA,
  index
})

export const getBancosDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_BANCOS_DADOS_BANCARIOS_SAGA,
  index
})

export const getMatriculasDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_MATRICULAS_DADOS_BANCARIOS_SAGA,
  index
})

export const getTiposPixDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_PIX_DADOS_BANCARIOS_SAGA,
  index
})

export const getTiposContaCrefazDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_TIPOS_CONTA_CREFAZ_DADOS_BANCARIOS_SAGA,
  index
})

export const getTemposContaDadosBancariosSaga = (index: number) => ({
  type: TYPES.GET_TEMPOS_CONTA_DADOS_BANCARIOS_SAGA,
  index
})

export const getDadosBancariosCadastradosSaga = (index: number) => ({
  type: TYPES.GET_DADOS_BANCARIOS_CADASTRADOS_SAGA,
  index
})

export const salvarDadosBancariosSaga = (index: number) => ({
  type: TYPES.SALVAR_DADOS_BANCARIOS_SAGA,
  index
})

export const visualizarDadosBancariosSagaDispatch = (
  id: string,
  index: number
) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_DADOS_BANCARIOS_SAGA_DISPATCH,
    id,
    index
  })

export const editarDadosBancariosSagaDispatch = (id: string, index: number) =>
  store.dispatch({
    type: TYPES.EDITAR_DADOS_BANCARIOS_SAGA_DISPATCH,
    id,
    index
  })

export const deletarDadosBancariosSagaDispatch = (id: string, index: number) =>
  store.dispatch({
    type: TYPES.DELETAR_DADOS_BANCARIOS_SAGA_DISPATCH,
    id,
    index
  })
