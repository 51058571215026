import React from 'react'
import classNames from 'classnames'
import { AiOutlineBank } from 'react-icons/ai'
import { FiBox } from 'react-icons/fi'
import { RiChat3Line, RiUserSearchLine } from 'react-icons/ri'
import { TabContent } from 'reactstrap'
import { TabAtendimento } from '../style'
import Contato from './Tabs/Contato'
import Matriculas from './Tabs/Matriculas'
import Informacoes from './Tabs/Informacoes'
import Produto from './Tabs/Produto'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { useSelector } from 'react-redux'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import ClienteAgendado from './Modals/ClienteAgendado'
import Upload from './Tabs/Upload'
import { MdOutlineFileUpload } from 'react-icons/md'
import DadosBancarios from './Tabs/DadosBancarios'
import { IoMdClipboard } from 'react-icons/io'

interface Props {
  index: number
}

function TabsAtendimento({ index }: Props) {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  function setActiveTab(numeroTab: number) {
    dispatch(atendimentoActions.setTabAtendimentoActive(numeroTab, index))
  }

  return (
    <>
      <ul className="nav d-flex gap-3 mb-2" id="myTab" role="tablist">
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(1)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 1 && 'active'
            )}
          >
            <RiChat3Line className="icon" /> Contato
          </TabAtendimento>
        </li>
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(2)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 2 && 'active'
            )}
          >
            <FiBox className="icon" /> Produtos
          </TabAtendimento>
        </li>
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(3)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 3 && 'active'
            )}
          >
            <RiUserSearchLine className="icon" /> Informações
          </TabAtendimento>
        </li>
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(6)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 6 && 'active'
            )}
          >
            <AiOutlineBank className="icon" /> Dados bancários
          </TabAtendimento>
        </li>
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(4)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 4 && 'active'
            )}
          >
            <IoMdClipboard className="icon" /> Matrículas
          </TabAtendimento>
        </li>
        <li role="presentation">
          <TabAtendimento
            role="button"
            onClick={() => {
              setActiveTab(5)
            }}
            className={classNames(
              atendimentosAbertos[index].tabAtendimento === 5 && 'active'
            )}
          >
            <MdOutlineFileUpload className="icon" /> Upload anexos
          </TabAtendimento>
        </li>
      </ul>
      <TabContent activeTab={atendimentosAbertos[index].tabAtendimento}>
        <Contato index={index} />
        <Produto index={index} />
        <Informacoes index={index} />
        <Matriculas index={index} />
        <Upload index={index} />
        <DadosBancarios index={index} />
      </TabContent>
      <ClienteAgendado
        isOpen={atendimentosAbertos[index].modalAtendimentoAgendado}
        onToggle={() =>
          dispatch(
            atendimentoActions.setModalAtendimentoAgendado(
              !atendimentosAbertos[index].modalAtendimentoAgendado,
              atendimentosAbertos[index].cpf
            )
          )
        }
        aceitaFunc={() =>
          dispatch(atendimentoActions.finalizaAgendamentoSaga(index))
        }
      />
    </>
  )
}

export default TabsAtendimento
