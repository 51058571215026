import React, { useCallback, useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import CustomSelect from 'components/Select'
import { UploadArquivo } from 'components/UploadArquivosComponent'
import useRedux from 'hooks/useRedux'
import { MdOutlineFileUpload } from 'react-icons/md'
import { RiBookOpenLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Col, Label, Modal, ModalHeader, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { PRODUCAO } from 'store/modules/producao/types'
import { SimpleTitleCard } from 'styles/global'
import { CardUpload } from 'views/producao/style'
import * as producaoActions from 'store/modules/producao/actions'
import { toast } from 'react-toastify'
import { COLUMNS_UPLOAD_ANEXOS } from 'views/producao/constants/columns'

interface Props {
  isOpen: boolean
  onToggle: () => void
}

const PendenciaAnexoCrefaz = ({ isOpen, onToggle }: Props) => {
  const { modalPendenciasAnexoCrefaz } = useSelector<
    ApplicationState,
    PRODUCAO
  >((state) => state.producao)

  const [isTipoArquivoInvalid, setIsTipoArquivoInvalid] = useState(false)
  const [isUploadedFileInvalid, setIsUploadedFileInvalid] = useState(false)

  type Fields = PRODUCAO['modalPendenciasAnexoCrefaz']['register']
  const onChange = <T extends keyof Fields>(value: Fields[T], field: T) => {
    dispatch(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        {
          ...modalPendenciasAnexoCrefaz.register,
          [field]: value
        },
        'register'
      )
    )
  }

  const { dispatch } = useRedux()

  const setPage = useCallback(
    (value: number) => {
      dispatch(
        producaoActions.setModalPendenciasAnexoCrefazFields(
          {
            ...modalPendenciasAnexoCrefaz.paginacaoAnexos,
            page: value
          },
          'paginacaoAnexos'
        )
      )
      dispatch(producaoActions.getAnexosSaga())
    },
    [dispatch, modalPendenciasAnexoCrefaz]
  )

  const setPageSize = useCallback(
    (value: number) => {
      dispatch(
        producaoActions.setModalPendenciasAnexoCrefazFields(
          {
            ...modalPendenciasAnexoCrefaz.paginacaoAnexos,
            page: 0,
            pageSize: value
          },
          'paginacaoAnexos'
        )
      )
      dispatch(producaoActions.getAnexosSaga())
    },
    [dispatch, modalPendenciasAnexoCrefaz]
  )

  const onSave = () => {
    const tipoArquivo = modalPendenciasAnexoCrefaz.register.tipoArquivo
    const uploadedFile = modalPendenciasAnexoCrefaz.register.uploadedFile

    setIsTipoArquivoInvalid(!tipoArquivo)
    setIsUploadedFileInvalid(!uploadedFile)

    if (tipoArquivo && uploadedFile) {
      dispatch(producaoActions.salvarAnexoSaga())
    } else {
      toast.error('Preencha todos os campos obrigatórios antes de salvar')
    }
  }

  useEffect(() => {
    if (modalPendenciasAnexoCrefaz.isOpen) {
      dispatch(producaoActions.getTiposArquivoCrefazSaga())
      dispatch(producaoActions.getAnexosSaga())
    }
  }, [modalPendenciasAnexoCrefaz.isOpen, dispatch])

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" />
          Pendência de anexos Crefaz
        </SimpleTitleCard>
      </ModalHeader>
      <div className="container-fluid d-flex flex-column gap-2 py-3">
        <CardUpload>
          <h4 className="d-flex align-items-center column-gap-10">
            Envio de documentos
          </h4>
          <Col md={4}>
            <Label className="label-12">Tipo de documento*</Label>
            <CustomSelect
              options={modalPendenciasAnexoCrefaz.tiposArquivo}
              value={modalPendenciasAnexoCrefaz.register.tipoArquivo}
              onChange={(e) => {
                onChange(e, 'tipoArquivo')
              }}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={isTipoArquivoInvalid}
            />
          </Col>
          <Row className="mt-4">
            <UploadArquivo
              onChange={(e) => {
                onChange(e, 'uploadedFile')
              }}
              value={modalPendenciasAnexoCrefaz.register.uploadedFile}
              invalid={isUploadedFileInvalid}
              removeSection
              acceptedFiles={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg', '.jpg'],
                'application/pdf': ['.pdf']
              }}
              message="Somente arquivos PNG, JPEG e PDF"
            />
          </Row>
          <Row className="mt-4">
            <div onClick={onSave} className="add-btn btn">
              <MdOutlineFileUpload className="icon" />
              Clique para enviar
            </div>
          </Row>
        </CardUpload>
        <CustomTable
          columns={COLUMNS_UPLOAD_ANEXOS}
          data={modalPendenciasAnexoCrefaz.anexos}
          paginationMode="server"
          rowCount={modalPendenciasAnexoCrefaz.paginacaoAnexos.totalRegistros}
          page={modalPendenciasAnexoCrefaz.paginacaoAnexos.page}
          pageSize={modalPendenciasAnexoCrefaz.paginacaoAnexos.pageSize}
          setPage={(e) => {
            setPage(e)
          }}
          setPageSize={(e) => {
            setPageSize(e)
          }}
        />
      </div>
    </Modal>
  )
}

export default PendenciaAnexoCrefaz
